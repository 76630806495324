/* ==================================================================================================================
 * OpenGoSim Bluebell: app/styles.scss
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
$fa-font-path: '~node_modules/font-awesome/fonts';

body {
  // Used in old AdminLTE
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// PRELOADER
/* Firefox 16+, IE 10+, Opera */
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Firefox 16+*/
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

/* Firefox 16+, IE 10+, Opera */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#preLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #ffffff;
  & > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #dd4b39;
    transform: translate3d(0, 0, 0);
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #3c8dbc;
      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f39c12;
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}

// SIGNIN FORM
.form-login {
  margin: 0 auto;
  padding-top: 30px;
  max-width: 330px;
}

// DISABLED LINKS
a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

// POINTY FINGER
.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

// LOADING SPINNER
.loading-spinner {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dd4b39;
  transform: translate3d(0, 0, 0);
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3c8dbc;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f39c12;
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
}

.loading-spinner-sm {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #dd4b39;
  transform: translate3d(0, 0, 0);
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #3c8dbc;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  &:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #f39c12;
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
}

// SELECTOR THINGS IN HEADER
.header-select-list {
  padding-left: 0px;
  li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
    list-style: none;
  }
}

// HIDING SEARCH ON SELECT2
.hideSearch .select2-search,
.hideSearch .select2-results,
.hideSearch .select2-dropdown {
  display: none;
}

// MASSIVE MODAL
.modal-fullscreen {
  width: 96%;
  height: 90%;
  margin-left: 2%;
  margin-top: 2%;
  .modal-content {
    height: auto;
    border-radius: 0;
  }
}

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.scrollbar {
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
}

.validate {
  &.ng-valid > .select2 {
    border: 1px solid #00a65a;
    border-left: 5px solid #00a65a; /* green */
  }
  &.ng-invalid > .select2 {
    border: 1px solid #dd4b39;
    border-left: 5px solid #dd4b39; /* red */
  }
  .select2 > .selection > .select2-selection {
    border: none;
  }
}

// MAKING BOOTSTRAP SLIDER YELLOW
.slider-track > .slider-selection {
    background: #f39c12;
}

.slider-tick.round.in-selection {
  background: #f39c12;
}

.slider-handle {
  background: #f39c12;
}

body .ui-treetable .ui-treetable-thead > tr > th {
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #333 !important;
}

body .ui-treetable .ui-treetable-tbody > tr > td {
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333 !important;
}
